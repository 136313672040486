<script setup lang="ts">
import { LazyHydrationWrapper } from 'vue3-lazy-hydration';
import type { SharedOffcanvas } from '~/components/shared/SharedOffcanvas.vue';
const { sessionContext, languageIdChain } = useSessionContext();
const { isLoggedIn } = useUser();

defineOptions({ name: 'AccountLayout' });

const { loadNavigationElements } = useNavigation();
const { data } = useLazyAsyncData(
    'mainNavigation',
    () => {
        return loadNavigationElements({ depth: sessionContext.value?.salesChannel?.navigationCategoryDepth || 2 });
    },
    {
        watch: [isLoggedIn, languageIdChain],
    },
);
provide('swNavigation-main-navigation', data);

const { loadNavigationElements: loadFooterNavigationElements } = useNavigation({
    type: 'footer-navigation',
});
const { data: footerData } = useLazyAsyncData(
    'mainFooterNavigation',
    () => {
        return loadFooterNavigationElements({ depth: 2 });
    },
    {
        watch: [isLoggedIn, languageIdChain],
    },
);
provide('swNavigation-footer-navigation', footerData);

const offcanvas = inject<SharedOffcanvas>('offcanvas') as SharedOffcanvas;

const route = useRoute();
const routeLvl = computed(() => route.path.split('/').length);

const localePath = useLocalePath();
</script>

<template>
    <div class="flex flex-col min-h-dvh">
        <NuxtLoadingIndicator :color="false" />
        <LayoutNotifications />
        <LayoutHeader />

        <main class="container flex-1">
            <LayoutLegacyBrowserWarning />
            <NuxtLink
                v-if="routeLvl > 3"
                class="flex items-center gap-2.5 py-5 transition-colors hover:text-brand-primary"
                role="menuitem"
                tabindex="-1"
                :to="localePath('/account')"
            >
                <LazySvgoArrowLeftLong class="w-6 h-6" /> {{ $t('account.backToDashboard') }}
            </NuxtLink>
            <slot />
        </main>

        <LazyHydrationWrapper :when-visible="true">
            <LayoutFooter />
        </LazyHydrationWrapper>

        <SharedOffcanvasContent
            id="layout-login-offcanvas"
            :offcanvas="offcanvas"
        />
    </div>
</template>
